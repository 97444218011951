import axios from '../index';
// const { get, post, put, del } = axios;
const { get, post, patch} = axios;
import config from '../config';
const { relApi, jybApi } = config;

// 单轮对话 （普通对话）
export function agentDialog(data) {
  return post(`${relApi}/common/agentDialog/`, data);
}
// 单轮对话 （核保模式）
export function underwriting(data) {
  return post(`${relApi}/common/underwriting/`, data);
}


// 历史对话
export function agentDialogHistory(data) {
  return post(`${relApi}/common/agentDialogHistory/?page=${data.currentPage}`, data);
}

// 获取产品推荐
export function recommendProduct(data) {
  return get(`${relApi}/common/RecommendProducts/`, data);
}

// 获取欢迎语
export function into_count() {
  let broker_id = localStorage.getItem('broker_id');
  return get(`${relApi}/common/dialog/into_count/?broker_id=${broker_id}`);
}
// 保单信息
export function uploadFiles(data) {
  return post(`${relApi}/common/guarantee_slip_recognition/`, data);
}

// 点赞、踩
export function appraise(sentence_id,data) {
  return patch(`${relApi}/common/updateConversation/${sentence_id}/`, data);
}

// 获取常见问题
export function getQuestion() {
  return get(`${relApi}/common/question/`);
}


// 鲸云保获取代理人角色
export function getJYBRole(jyb_id) {
  return post(`${jybApi}/should/know/getRole`, {"userId": jyb_id});
}

// 鲸云保获取代理人所在公司的部门和标签
export function companyDepartment(jyb_id) {
  return post(`${jybApi}/should/know/getDeptList`, {"userId": jyb_id});
}

// 鲸云保获取部门,标签下的常见问题
export function deptQuestion(data) {
  return post(`${jybApi}/should/know/getQuestion`, data);
}


// 鲸云保 获取 代理人头像二维码信息
export function getBrokerQR(data) {
  return post(`${jybApi}/should/know/getPeopleDetail`, data);
}

// 匹配问题
export function QuestionMatch(data) {
  return post(`${relApi}/common/QALike/`, data);
}

// 保存常见问题对话记录
export function saveQuestionRecord(data) {
  return post(`${relApi}/common/should_know/`, data);
}

// 获取办事导航是否显示手机号
export function getShowMobile() {
  return get(`${relApi}/api/v1/qa_collect/should_know/show_user_mobile)/`);
}
// 保存常见问题对话记录
export function todayRemind(data) {
  return post(`${relApi}/user/bebot/today_remind/`, data);
}
// 提交人工服务的手机号
export function postMobileToArtificial(data) {
  return post(`${relApi}/common/dialog/artificial/`, data);
}
